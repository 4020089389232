import { fetchQuery } from "utils/relay";
import { emptyProxyObject } from "utils/utils";
import { Action, Thunk, thunk, action } from "easy-peasy";

export interface ItemModel {
    item: any;
    items: any[];
    clearItem: Action<ItemModel>;
    clearItems: Action<ItemModel>;
    setItem: Action<ItemModel, any>;
    setItems: Action<ItemModel, any[]>;
    fetchItem: Thunk<ItemModel, any>;
    fetchItems: Thunk<ItemModel, any>;
}

const ItemStore: ItemModel = {
    item: emptyProxyObject,
    items: [],

    clearItem: action((state) => {
        state.item = emptyProxyObject;
    }),

    clearItems: action((state) => {
        state.items = [];
    }),

    setItem: action((state, item) => {
        state.item = item;
    }),

    setItems: action((state, items) => {
        state.items = items;
    }),

    fetchItem: thunk((actions, { query, variables }) => {
        actions.clearItem();
        return fetchQuery(query, variables).then((itemWrapper: any) => {
            const key = Object.keys(itemWrapper)[0];
            actions.setItem(itemWrapper[key]);
        });
    }),

    fetchItems: thunk((actions, { query, variables }) => {
        actions.clearItems();
        return fetchQuery(query, variables).then((itemsWrapper: any) => {
            const key = Object.keys(itemsWrapper)[0];
            actions.setItems(itemsWrapper[key]);
        });
    }),
};

export default ItemStore;
